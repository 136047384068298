<template>
  <div  ref="containerRef"
        :style="`opacity : ${contentReady ? 1 : 0}`"
        style="transition: opacity 0.3s ease"
  >
    <!-- Payment Plans-->
    <div class="d-flex flex-wrap  pb-4" v-if="course.has_payment_plans">
        <template   v-for="(plan , i ) in paymentPlansFiltered">

          <v-sheet
              :key="i + 'plan'"
              style="border-radius: 100px"
              class="pa-2 px-4 mb-2 mr-2"
              v-ripple
              :color="selectedPlan === plan.value ? BLOCK_COLOR_PARAM(element , 'button_bg' , blockStyle ) : 'transparent'"
          >
            <h5 :key="SCALE_COEFFICIENT + 'plan'" :style="buttonStyle(plan.value)">{{  plan.text  }} </h5>
          </v-sheet>


        </template>

      </div>
    <!-- Content design-->
    <div v-for="(module , i) in content" :key="i">

      <v-sheet :height="element.config.gutter_vertical / (i === 0 ? 2 : 1)" color="transparent"
               class="d-flex "
               :class="[{'align-center' : i > 0}]"
      >
        <v-divider style="width: 100%;"
                   :style="lineStyle"
        />
      </v-sheet>

      <v-row
          :style="getRowStyle"
          no-gutters>
        <v-col
            :style="getColStyle(i)"
            :cols="leftRowColumns"
        >
          <h4 :key="SCALE_COEFFICIENT + 'module'"
              :style="BLOCK_TEXT_CSS(element , blockStyle , 'course-content' , 'module' , null, null, DEFAULT_BLOCK_WIDTH)"  >
            {{  module.name  }}
            <br>
          </h4>
        </v-col>
        <v-col
            :style="getColStyle(i)"
            :cols="rightRowColumns"
        >

          <span v-for="(topic, j) in module.topics" :key="`topic_${i}${j}`">

            <h5 :key="SCALE_COEFFICIENT + 'topic'"
                :style="BLOCK_TEXT_CSS(element , blockStyle , 'course-content' , 'topic' , null , null , SCALE_COEFFICIENT)"
                :class="[{'pt-6' : j>0}]"
            >
                    {{ topic.name }}
            </h5>

              <div >
                <h5 v-for="(component, k) in topic.components" :key="`component_${i}${j}${k}${SCALE_COEFFICIENT}`"
                    class="font-weight-regular d-flex align-center">
                  <v-icon
                      :size="BLOCK_FONT_VARIABLE(element , 'size' , 'course-content' , 'component' , )*1.2*SCALE_COEFFICIENT"
                      :color="BLOCK_COLOR_PARAM(element , 'course-content_icon' , blockStyle)"
                      class="mr-2"
                      >
                    {{ getComponentIcon(component.entity_type) }}
                  </v-icon>

                  <h5  :style="BLOCK_TEXT_CSS(element , blockStyle , 'course-content' , 'component', null , null , SCALE_COEFFICIENT)" >
                    {{ component.name }}
                  </h5>

                </h5>
              </div>
            </span>


          </v-col>
      </v-row>


      </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "elementLineEditor",
  props : {
    value : {
      type : Object,
      default() { return {} }
    },
    blockStyle : {
      type : String
    }
  },
  data() {
    return {
      contentReady : false,
      firstLoad : false,
      height : 0,
      element : { config : {}},
      courseData : {},
      placeholderContent : [
        { name : 'Module 1: Introduction to Design' , topics : [
            { name : 'Chapter 1: What is Design? ' , components : [
                { name : 'Lecture 1: Definition and Scope of Design' },
                { name : 'Lecture 2: Historical Overview of Design' },
                { name : 'Lecture 3: Design as Problem-Solving Process' }
              ]
            },
            { name : 'Chapter 2: Importance of Design in Various Industries? ' , components : [
                { name : 'Lecture 1: Design in Graphic Industry' , type : 'lecture' },
                { name : 'Lecture 2: Design iun Product Design Industry' , type : 'lecture' },
                { name : 'Lecture 3: Design in UX/UI Design Industry' , type : 'lecture' }
              ]
            }
          ]
        },
        { name : 'Module 2: Introduction to Design' , topics : [
            { name : 'Chapter 1: What is Design? ' , components : [
                { name : 'Lecture 1: Definition and Scope of Design' },
                { name : 'Lecture 2: Historical Overview of Design' },
                { name : 'Lecture 3: Design as Problem-Solving Process' }
              ]
            },
            { name : 'Chapter 2: Importance of Design in Various Industries? ' , components : [
                { name : 'Lecture 1: Design in Graphic Industry' },
                { name : 'Lecture 2: Design iun Product Design Industry' },
                { name : 'Lecture 3: Design in UX/UI Design Industry' }
              ]
            }
          ]
        }

      ],
      selectedPlan : null
    }
  },
  computed : {
    siteConfig() {
      return this.$store.state.avalon.config
    },
    domElement() {
      return this.$refs.containerRef
    },

    leftRowColumns() {
      if ( this.MOBILE_VIEW || this.element.config.single_column ) {
        return 12
      }
      return this.element.config.list_proportion === '2-3' ? 4 : 6
    },
    rightRowColumns() {
      if ( this.MOBILE_VIEW || this.element.config.single_column ) {
        return 12
      }
      return this.element.config.list_proportion === '2-3' ? 8 : 6
    },
    getRowStyle() {
      let style = ''
      let paddingValue = this.element.config.gutter || 0
      if ( paddingValue && !( this.SM || this.MOBILE_VIEW ) ) {
        style += `margin-left : -${paddingValue/2}px;`
        style += `margin-right : -${paddingValue/2}px;`
      }
      return style
    },
    lineStyle() {
      let style = ''
      if ( this.element.config.thickness  ) {
        style +=  `border-width : ${this.element.config.thickness}px; `
      }
      style += `border-color : ${this.BLOCK_COLOR_PARAM(this.element ,'course-content_line' , this.blockStyle )}`

      return style
    } ,

    restrictedPlans() {
      return this.element.config.restricted_plans || []
    },
    restrictedComponents() {
      return this.element.config.restricted_components || {}
    },
    configCourseId() {
      return this.element.config.course
    },
    course() {
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if (!this.element.config.course && this.$store.state.avalonPage.course ) {
        return this.$store.state.avalonPage.course
      }
      if ( checkId === this.element.config.course ) {
        return this.$store.state.avalonPage.course
      }
      return   this.courseData
    },
    content() {
      if (!this.course.uuid ) {
        return this.placeholderContent
      }
      let items = this.COPY(this.course.modules)

      if ( !items ) {
        items = []
      }

      if ( this.course.has_payment_plans && this.course.payment_plans && this.selectedPlan  ) {

        this.course.modules.forEach((module , m) => {
          module.topics.forEach((topic, t) => {
            items[m].topics[t].components = items[m].topics[t].components
                .filter(el=> el.payment_plan_id && el.payment_plan_id.includes(this.selectedPlan))
          })
        })

        this.course.modules.forEach((module , m) => {
          items[m].topics = items[m].topics.filter(el=> el.components.length > 0)
        })

        items = items.filter( el => el.topics.length > 0 )

      }

      if ( this.restrictedComponents && this.restrictedComponents[this.selectedPlan] ) {
        items.forEach((module , m) => {
          module.topics.forEach((topic, t) => {
            items[m].topics[t].components = items[m].topics[t].components
                .filter(el=> !(this.restrictedComponents[this.selectedPlan] || [] ).includes(el.uuid) )
          })
        })
      }

      return items
    },
    paymentPlansFiltered() {
      if ( !this.course ) {
        return []
      }
      if ( !this.course.has_payment_plans ) {
        return []
      }
      if ( !this.course.payment_plans ) {
        return []
      }

      let items = this.COPY(this.course.payment_plans)
      items = items.filter( el => !this.restrictedPlans.includes(el.value))
      return items

    },
    gridIconsRows() {
      switch (this.element.config.grid_icons_rows) {
        case 2 : return 6
        default : return 12
      }
    },
    gridNumbersRows() {
      switch (this.element.config.grid_numbers_rows) {
        case 1 : return 12
        case 2 : return 6
        case 3 : return 4
        case 4 : return 3
        default : return 6
      }
    }
  },
  watch : {
    paymentPlansFiltered : {
      handler(value) {
        if ( value && value[0] ) {
          this.selectedPlan = value[0].value
        }
      },
      deep : true
    },
    'element.width'() {
      this.getHeight()
    },
    'element.config' : {
      handler() {
        setTimeout( this.getHeight , 100)
      },
      deep : true
    },
    'siteConfig.fonts.elements' : {
      handler() {
        setTimeout( this.getHeight , 100)
      },
      deep : true
    },
    height() {
      this.updateHeight()
      if ( this.firstLoad &&  this.height > 0 ) {
        this.firstLoad = false
      }
      setTimeout(this.updateHeight , 300 )
    },
    content : {
      handler(value , old ) {
        if ( this.EQ(value , old) )  {
          return
        }
        setTimeout(this.getHeight , 300)
        this.getHeight()
      },
      deep : true
    },
    configCourseId() {
      this.handleCourse()
    },
    value : {
      handler() {
        if ( JSON.parse(JSON.stringify(this.value)) !== JSON.parse(JSON.stringify(this.element)) ) {
          this.element = JSON.parse(JSON.stringify(this.value))
        }
      },
      deep : true
    }
  },
  methods : {
    ...mapActions('avalonPage', ['EDIT_ELEMENT']),
    ...mapActions('courses', ['GET_COURSE_EDIT']),

    updateHeight() {
      if ( this.height === 0 ) {
        return
      }
      this.$emit('actual-height-update' , this.height)
      setTimeout(() => {
        this.$emit('actual-height-update', this.height )
      }, 10)
      if ( !this.contentReady ) {
        return
      }
      setTimeout(() => {
        this.$emit('update-height', this.height )
      }, 10)

    },

    getHeight() {
      setTimeout(()=> {
        let domElement = this.$refs.containerRef
        if (domElement) {
          this.height = domElement.clientHeight
        }
      },10)
    },

    getColStyle(index) {
      let style = ''
      let paddingValue = this.element.config.gutter || 0
      if ( paddingValue && !(this.SM || this.MOBILE_VIEW) ) {
        style += `padding-left : ${paddingValue/2}px;`
        style += `padding-right : ${paddingValue/2}px; `
      }
      if ( this.element.config.style === 'grid_numbers'  && index >= this.element.config.grid_columns && this.element.config.gutter_vertical ) {
        style += `padding-top : ${this.element.config.gutter_vertical}px;`
      }

      return style
    },

    buttonStyle(plan) {
      let style = this.BLOCK_TEXT_CSS(this.element , this.blockStyle , 'buttons' , 'text', null , null , this.DEFAULT_BLOCK_WIDTH)
      style = style.slice(13)
      style += `color : ${ this.BLOCK_COLOR_PARAM(this.element , plan === this.selectedPlan ? 'button_text' : 'button_text_secondary'  , this.blockStyle) };`
      return style
    },

    getComponentIcon(type) {
      switch ( type ) {
        case 'lecture'    : return 'mdi-book-open'
        case 'task'       : return 'mdi-content-paste'
        case 'task_video' : return 'mdi-cast-education'
        case 'test'       : return 'mdi-order-bool-ascending-variant'
        case 'task_test'  : return 'mdi-order-bool-ascending-variant'
        case 'video'      : return 'mdi-video'
        case 'file'       : return 'mdi-file'
        default : return 'mdi-bookmark'
      }
    },


    parseNewLine(input) {
      if ( !input ) {
        return ''
      }
      return input.replace(/\n/g,'<br>')
    },
    async handleCourse() {
      this.contentReady = false
      let checkId = this.$store.state.avalonPage.course ? this.$store.state.avalonPage.course.uuid : null
      if ( this.element.config.course !== checkId ) {
        let data = {
          uuid : this.element.config.course,
          lang : this.$store.state.avalon.selectedLang
        }
        let result = await this.GET_COURSE_EDIT(data)
        if ( !result ) {
          this.contentReady = true
          this.getHeight()
          return
        }
        this.courseData = result
        this.contentReady = true
        this.getHeight()
      } else {
        this.contentReady = true
        this.getHeight()
      }
      this.getHeight()
      if ( this.course.has_payment_plans && this.course.payment_plans && this.course.payment_plans[0]) {
        this.selectedPlan = this.course.payment_plans[0].value || 'all'
      } else {
        this.selectedPlan = 'all'
      }
    }
  },
  mounted(){
    if ( this.value ) {
      this.element = JSON.parse(JSON.stringify(this.value))
      this.$emit('input',this.element)
    }
    this.handleCourse()
  }
}
</script>

<style scoped>

</style>